<template>
  <div class="vg_wrapper">
    <el-card>
      <div>
        合同日期
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="录入合同日期"
          end-placeholder="录入合同日期"
        />
        装运日期
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange2"
          type="daterange"
          range-separator="至"
          start-placeholder="录入装运日期"
          end-placeholder="录入装运日期"
        />
        <el-button type="primary" size="small" class="vd_export" @click="getStatsList()">查询</el-button>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="exportToExcel('allTable')">导出到Excel</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
      </div>
      <search-table
        ref="multiTable"
        id="multiTable"
        :data="tableData"
        :tableRowKey="`catp_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @getTableData="getStatsList"
        :page-size="50"
        :show-summary="true"
        :summariesColumns="['catnip_num']"
      ></search-table>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch } from '@api/request';
import { catpAPI } from '@api/modules/catp';
import { custAPI } from '@/api/modules/cust';
import helper from '@assets/js/helper.js';
import htmlToExcel from '@/plugins/htmlToExcel';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getDateNoTime } from '@assets/js/dateUtils';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { podrAPI } from '@api/modules/podr';
import { downloadFile } from '@api/public';
import { deliAPI } from '@api/modules/deli';

export default {
  name: 'OureList',
  components: {
    DynamicUTable,
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      tableProperties: [
        this.$store.state.index,
        {
          prop: 'scon_cndate',
          itemType: 'date',
          label: '合同日期',
          widthAuto: true,
          input: false,
          sortable: true,
          formatter: val => getDateNoTime(val, true)
        },
        {
          prop: 'scon_shdate',
          itemType: 'date',
          label: '装运日期',
          widthAuto: true,
          input: false,
          sortable: true,
          formatter: val => getDateNoTime(val, true)
        },
        {
          prop: 'cust_abbr',
          itemType: 'input',
          label: '客户简称',
          widthAuto: true,
          sortable: false,
          input: true
          // subItem: { type: 'input', required: true }
        },
        { prop: 'scon_cust_no', itemType: 'input', label: '客户订单号', widthAuto: true, sortable: false, input: true },
        { prop: 'scon_no', itemType: 'input', label: '销售合同号', widthAuto: true, sortable: false, input: true },
        {
          prop: 'catnip_type',
          itemType: 'select',
          label: '猫草种类',
          widthAuto: true,
          sortable: true,
          input: true,
          options: [
            { value: 'A类猫草', label: 'A类猫草' },
            { value: 'B类猫草', label: 'B类猫草' }
          ]
        },
        { prop: 'catnip_num', itemType: 'input', label: '总数量', widthAuto: true, sortable: true, input: false }
      ],
      tableData: [],
      searchForm: {
        cust_id: null,
        scon_cust_no: null,
        scon_no: null,
        mtrb_name: null,
        timeValue: [],
        timeValue1: []
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      custOptionList: []
    };
  },
  mounted() {
    this.initData();
  },
  filters: {
    formatDate1(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    initData() {
      this.getStatsList();
      // this.initCust();
    },
    getStatsList() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.startTime = startTime ? startTime / 1000 : null;
      searchForm.endTime = endTime ? endTime / 1000 : null;
      let [startTime2, endTime2] = this.dateRange2 || [];
      searchForm.startTime2 = startTime2 ? startTime2 / 1000 : null;
      searchForm.endTime2 = endTime2 ? endTime2 / 1000 : null;
      getNoCatch(catpAPI.getCatps, searchForm).then(({ data }) => {
        this.loading = false;
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    // 获取客户
    initCust() {
      get(custAPI.getAllCustsV1, { status: 2 })
        .then(res => {
          if (res.data.code === 0) {
            this.custOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.multiTable.resetFields();
      this.getStatsList();
    },
    // 查询方法
    getStatsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 导出excel
    exportToExcel(val) {
      // htmlToExcel.getExcel(`#multiTable`, '猫草表单');
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let searchForm = this.$refs.multiTable.searchForm;
          let [startTime, endTime] = this.dateRange || [];
          searchForm.startTime = startTime ? startTime / 1000 : null;
          searchForm.endTime = endTime ? endTime / 1000 : null;
          let [startTime2, endTime2] = this.dateRange2 || [];
          searchForm.startTime2 = startTime2 ? startTime2 / 1000 : null;
          searchForm.endTime2 = endTime2 ? endTime2 / 1000 : null;
          get(catpAPI.export_catps, searchForm)
            .then(res => {
              if (res.data.code === 0) {
                downloadFile({ fileUrl: res.data.data.url, fileName: res.data.data.fileName });
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.initData();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
